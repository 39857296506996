$base-color: rgb(142, 149, 202);
$hover-color: rgb(97, 105, 167);
$menu-color: rgb(215, 210, 242);
$blue-color: rgb(32, 47, 158);

.Login {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  background-color: #fff;
  // position: relative;
  // z-index: 1;
  background-color: transparent;
  .login-info {
    padding: 0px 0px 10px;
    font-size: 27px;
    letter-spacing: 0.6px;
    font-family: "Cardo", serif;
  }
}
.Fields {
  display: block;
  align-items: center;
}
.field {
  display: flex;
  width: 422px;
  min-height: 38px;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  color: rgb(5, 5, 5);
  font-size: 19px;
  font-family: "Cardo", serif;
  font-family: "Charter", serif;
  letter-spacing: 2px;
  input {
    height: 50px;
  }
}
.login_buttons button {
  width: 124px;
  height: 45px;
  margin: 10px;
  font-size: 17px;
  font-family: "Cormorant", serif;
  font-family: "Charter", serif;
  letter-spacing: 2px;
  //font-weight: 500;
  color: rgb(255, 255, 255);
  background-color: $base-color;
  border-radius: 7px;
  border: 0;
  transition-duration: 0.4s;
  &:hover {
    background-color: $menu-color;
    color: white;
  }
}
.field input {
  padding-left: 8px;
  font-size: 20px;
  font-family: "Cormorant", serif;
  width: 222px;
  height: 33px;
}

.Login p {
  text-decoration: underline;
  color: $blue-color;
  font-size: 16.4px;
  font-family: "Charter", serif;
  letter-spacing: 1.6px;
}

.orders-title {
  display: flex;
  justify-content: center;
  padding: 0px 0 12px 0;
  text-align: center;
  font-family: "Didot", serif;
  font-size: 33px;
  letter-spacing: 1.5px;
  color: #1c1c1c;
  font-style: italic;
  text-shadow: 1px 2px rgba(128, 128, 128, 0.2);
  p {
    margin: 0;
    //cursor: pointer;
    padding: 0;
    font-size: 33px;
  }
}
