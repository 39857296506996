.Edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
  padding-top: 11px;
  .field {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 5px;
    color: rgb(5, 5, 5);
    font-family: "Cardo", serif;
    font-family: "Charter", serif;
    letter-spacing: 2px;
    input,
    textarea {
      border-radius: 8px;
      border: solid rgb(169, 169, 169) 1px;
      padding: 2px 9px;
      font-size: 16px;
      font-family: "georgia", serif;
      letter-spacing: 0.6px;
      width: 280px;
      height: 54px;
    }
  }
  .field-body {
    textarea {
      height: 147px;
    }
  }
  label span {
    min-width: 140px;
    text-align: center;
    font-size: 18px;
    padding-bottom: 5px;
    font-family: "Georgia", serif;
  }
  span.error {
    color: red;
  }
  p {
    margin: 10px;
  }
  h3.preview {
    padding: 10px;
    padding-bottom: 0;
    margin-top: 8px;
    margin-bottom: 0;
  }
  .preview {
    max-width: 600px;
    padding: 12px;
    padding-top: 0;
  }
  .Upload {
    display: grid;
    //justify-content: center;
    gap: 15px;
    max-width: 280px;
    input,
    button {
      font-size: 16px;
      width: 100px;
    }
  }


  iframe {
    width: 0;
    height: 0;
    padding: 0;
    margin: 0;
  }
}
