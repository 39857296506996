$color-blue: rgb(32, 47, 158);
.menuItem1 {
  text-align: center;
  cursor: pointer;
}
/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 28px;
 // left: 30px;
  right: 20px;
  top: 32px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #f4f3f3;
  border-radius: 3px;
  position: relative;
  left: 10px;
  top: 10px;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #f3f3f3;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  padding-left: 20px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  //position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  // background: #373a47;
  background-color: $color-blue;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #f4f4f4;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 11px 0;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: #f3f3f3;
  padding: 16px 0px ;
  font-size: 21px;
  letter-spacing: 1.8px;
  text-decoration: none;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(1, 1, 1);
//   background: rgba(31, 1, 1, 0);
  // position: absolute;
  // z-index: 1000;
  // width: 100%;
  // height: 100%;
//   background: rgba(0, 0, 0, 0.098) !important;
//   background: none !important;
 // opacity: 0.6 !important;
 // transition: opacity 0.3s ease 0s;
 }