.Article {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-width: 540px;
  margin: 20px 0px;
  padding: 0 8px;
  img {
    align-self: center;
    max-width: 100%;
    padding-bottom: 8px;
    border-radius: 12.6px;
  }
  .Article-Body {
    cursor: pointer;
    padding: 14px 0px;
  }
}
