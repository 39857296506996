$color-blue: rgb(32, 47, 158);
$icon-hover: rgba(255, 255, 255, 0.781);

.header {
  background-color: $color-blue;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  color: white;
  width: 100%;
  height: 92px;
  font-size: 18px;
  // font-family: Georgia, "Times New Roman", Times, serif;
  letter-spacing: 2.6px;
  .title {    
    font-size: 30px;
  }
  .userIcon {
    position: absolute;
    right: 78px;
    top: 32px;
    background-color: $color-blue;
    color: white;
    &:hover {
      color: $icon-hover;
    }
  }
  .userName {
    right: 115px;
    top: 36px;
    font-size: 18px;
  }
  a {
    color: white;
    text-decoration: none;
    &:hover {
      color: $icon-hover;
    }
  }
  a.active {
    background-color: transparent;
    color: white;
  }
}

@media (max-width: 550px) {
  .withUser {
    justify-content: left;
    .title {
      padding-left: 125px;
    }
  }
}
