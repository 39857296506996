.Comments {
  position: relative;
  display: flex;
  opacity: 0;
  flex-direction: column;
  max-width: 100%;
  max-width: 540px;
  margin: 20px 0px;
  padding: 2px 20px;
  transform: translateY(-100%);
  // z-index: -1;
  // height: 0;
  overflow: scroll;
}
.show {
  // position: relative;
  opacity: 1;
  // z-index: 1;
  // height: 30rem;
  transform: translateY(0);
  transition: all 0.4s ease-out 0.05s; // el, duratiaon, effect, delay
}
.hidden {
  display: none;
}
.Comment {
  transition: 0.6s;
  display: flex;
  justify-content: space-between;
  background-color: rgb(252, 252, 253);
  border: solid 1px rgb(211, 210, 210);
  font-weight: bold;
  font-size: 17px;
  max-width: 100%;
  max-width: 100%;
  min-width: 300px;
  min-height: 50px;
  padding: 10px 10px;
  margin: 5px 0;
  border-radius: 10px;
  label,
  .like-count {
    display: flex;
    font-size: 16px;
    color: darkblue;
    cursor: pointer;
  }
  h5 {
    font-size: 17px;
    margin: 1px 0 1px;
  }
  p {
    font-weight: normal;
    margin: 1px 0 1px;
    font-size: 16px;
  }
  span {
    min-width: 300px;
  }
  .btn {
    // box-sizing: content-box;
    font-size: 14px;
    height: 26px;
    width: 26px;
    padding: 0px;
    margin: 0 0 0 5px;
  }
}
