.App {
  display: grid;
  justify-content: center;
  font-size: 19px;
  h1,
  h2,
  h3 {
    text-align: center;
    font-family: "Charter";
    letter-spacing: 1.5px;
    margin-bottom: 8px;
  }
  .Main {
    padding: 5px 14px;
  }
  .PostsOfClickedUser {
    .title {
      display: flex;
      justify-content: center;
      font-weight: 600;
    }
  }
}
