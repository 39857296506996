
.Post-Info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;

  .user .icon {
    padding-bottom: 0px;
    margin-top: 4px;
  }
  .comments-count,
  .like-count,
  .user {
    display: flex;
    font-family: 'Open Sans', sans-serif;
    font-size: 17.5px;
    color: rgb(1, 32, 133);
    cursor: pointer;
  }
  .icon,
  .username,
  .comments-count,
  .like-count {
    &:hover {
      color: rgb(37, 37, 219);
      color: rgb(0, 104, 0);
    }
  }
  .comments-count:hover {
    text-decoration: underline;
  }
  .right,
  .comments-count,
  .like-count {
    display: flex;
    color: #26ab45;
  }
  .comment-icon {
    align-self: center;
  }
}