$color: rgba(151, 157, 221, 0.926);
$hover-color: rgba(174, 179, 238, 0.926);

.Post {
  position: relative;
  max-width: 100%;
  max-width: 540px;
  height: auto;
  margin: 14px 0 0;
  padding-bottom: 16px;
  border-bottom: 1px solid #e3e3e3;
  transition: all ease-out 0.5;
  > div:nth-child(1),
  > div:nth-child(2) {
    position: relative;
    z-index: 3;
  }
  > div:first-child {
    display: flex;
    flex-direction: column;
  }
  img {
    max-width: 100%;
    align-self: center;
    margin-bottom: 9px;
    border-radius: 12px;
  }
}
.Post-title {
  font-weight: bold;
  font-size: 25px;
  padding: 3px 3px 1.5px;
  font-weight: 500;
  letter-spacing: 1.1px;
}
.Post-subtitle {
  // font-style: italic;
  padding-left: 3px;
  padding-bottom: 10px;
}
.buttons {
  display: flex;
  justify-content: center;
}
.back-button {
  cursor: pointer;
  font-size: 19px;
  text-align: center;
  align-self: center;
  border: none;
  border-radius: 9px;
  padding: 5px 15px;
  min-width: 80px;
  min-height: 33px;
  margin: 10px;
  font-size: 17px;
  font-family: "Cormorant", serif;
  font-family: "Charter", serif;
  letter-spacing: 1.4px;
  color: rgb(255, 255, 255);
  background-color: $color;
  border-radius: 7px;
  border: 0;
  transition-duration: 0.4s;
  &:hover {
    background-color: $hover-color;
    color: white;
  }
}